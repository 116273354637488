import store from '@/store'

// import { fieldPatternMatcher } from '@casl/ability'
import { ref, watch } from '@vue/composition-api'

export default function useMarketsList() {
  const marketListTable = ref([])

  // Table Handlers
  const marketTableColumns = [
    { text: 'Short Name', value: 'marketShortName' },
    { text: 'Description', value: 'marketDescription' },
    { text: 'Status', value: 'isActive' },
    { text: 'Provider', value: 'marketProvider' },
    { text: 'Zone', value: 'marketZone' },

    // { text: 'Url Daily', value: 'marketUrlDaily' },
    // { text: 'Url Sold Yearly', value: 'marketUrlHistorical' },
    // { text: 'Url Sold 6mo', value: 'marketUrlParameters' },
    // { text: 'Url Sold 3mo', value: 'marketUrlParameters02' },
  ]

  const searchQuery = ref('')
  const options = ref({
    sortBy: ['marketShortName'],
    sortDesc: [false],
    itemsPerPage: 40,
  })
  const totalInvoiceListTable = ref(0)
  const loading = ref(false)
  const statusFilter = ref(null)
  const selectedTableData = ref([])

  const fetchMarkets = () => {
    store
      .dispatch('app-invoice/fetchMarkets', {
        q: searchQuery.value,
        options: options.value,
        status: statusFilter.value,
      })
      .then(response => {
        const { data, total } = response
        const filteredData = data
        marketListTable.value = filteredData
        totalInvoiceListTable.value = total
        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })
  }

  watch([searchQuery, statusFilter, options], () => {
    // start loading
    loading.value = true
    selectedTableData.value = []
    fetchMarkets()
  })

  return {
    marketTableColumns,
    searchQuery,
    options,
    marketListTable,
    statusFilter,
    totalInvoiceListTable,
    loading,
    selectedTableData,
    fetchMarkets,
  }
}
